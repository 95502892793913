import React from 'react';
import logo from './logo.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import './App.css';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/legal/function" render={() => {window.location.href="/legal/function.html"}} />
                <Route exact path="/legal/privacy" render={() => {window.location.href="/legal/privacy.html"}} />
                <Route exact path="/legal/community" render={() => {window.location.href="/legal/community.html"}} />
                <Route exact path="/legal/location" render={() => {window.location.href="/legal/location.html"}} />
            </Switch>
        </Router>

    );
}

function Home() {
    return (
        <div className="App">
            <div className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
            </div>
            <div className="App-body">
                Coming Soon!
            </div>
            <div className="App-footer">
                <ul className="Nav-items">
                    <li className="Nav-item">
                        <a className="Legal_link" href="/legal/function.html">서비스 이용약관</a>
                    </li>
                    <span className="Txt_bar">l</span>
                    <li className="Nav-item">
                        <a className="Legal_link" href="/legal/privacy.html">개인정보 수집 및 이용에 대한 안내</a>
                    </li>
                    <span className="Txt_bar">l</span>
                    <li className="Nav-item">
                        <a className="Legal_link" href="/legal/community.html">커뮤니티 가이드라인</a>
                    </li>
                    <span className="Txt_bar">l</span>
                    <li className="Nav-item">
                        <a className="Legal_link" href="/legal/location.html">위치정보 이용약관</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default App;
